<template>

  <body>
    <router-view />
  </body>
</template>

<style lang="scss">
* {
  font-family: 'Inter Variable', sans-serif;
}

body {
  font-family: 'Inter Variable', sans-serif;
}

footer {
  font-family: 'Inter Variable', sans-serif;
}
</style>
