import { createRouter, createWebHistory } from "vue-router";


const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("../components/Home.vue"),
    },
    {
        path: "/accountconfirm",
        name: "Confirmación de Cuenta",
        component: () => import("../components/accountConfirm.vue"),
    },
    {
        path: "/createAccount",
        name: "Creación de Cuenta",
        component: () => import("../components/preLogin.vue"),
    },
    {
        path: "/:pathMartch(.*)*",
        name: "Not Found",
        component: () => import("../components/NotFound.vue"),
    },
    {
        path: "/upcoming",
        name: "Proximamente",
        component: () => import("../components/upcoming.vue"),
    },
    {
        path: "/login",
        component: () => import("../components/Iniciosesioncorreo.vue"),
    },
    {
        path: "/signin",
        component: () => import("../components/Registrocorreo.vue"),
    },
    {
        path: "/home/vacantes",
        name: "Vista Vacantes",
        component: () => import("../components/PreviewVacantes.vue"),
    },
    {
        path: "/preuniversitario",
        name: "Formulario Pre",
        component: () => import("../components/ComunityPreparatoria.vue"),
    },
    {
        path: "/universitario",
        name: "Formulario Uni",
        component: () => import("../components/ComunityUniversitaria.vue"),
    },
    {
        path: "/exuniversitario",
        name: "Formulario Ex",
        component: () => import("../components/ComunityExuniversity.vue"),
    },
    {
        path: "/avisodeprivacidad",
        name: "Aviso De Privacidad",
        component: () => import("../components/Avisoprivacidad.vue"),
    },
    {
        path: "/login/redirect",
        component: () => import("../components/LoginRedirect.vue"),
    },
    {
        path: "/formularioempresa",
        component: () => import("../components/Formularioempresa.vue"),
    },
    {
        path: "/formulariouniversidades",
        component: () => import("../components/Formulariouniversidades.vue"),
    },
    {
        path: "/selectuser",
        name: "Usuario",
        component: () => import("../components/Users.vue"),
    },
    {
        path: "/selectstudent",
        name: "Estudiantes",
        component: () => import("../components/Students.vue"),
    },
    {
        path: "/users/resetPassword/:token",
        name: "RestaurarContraseña",
        component: () => import("../components/ResetPassword.vue"),
    },
    {
        path: "/vacantes/vista/:id",
        name: "Vacante",
        component: () => import("../components/VistaVacante.vue"),
    },
    {
        path: "/:slug/bolsa",
        name: "BolsaTrabajoUniversidades",
        component: () => import("../components/bolsaUniversitaria.vue"),
        props: true,
    },
    {
        path: "/:slug",
        name: "Universidades",
        component: () => import("../components/Universidad.vue"),
        props: true,
    },
    {
        path: "/universidad",
        name: "Universidad",
        meta: {
            required: true,
            roles: ["school"],
        },
        component: () => import("../components/layouts/universidad.layouts.vue"),
        children: [
            {
                path: "/universidad/perfil",
                name: "Perfil Universidad",
                meta: {
                    required: true,
                    roles: ["school"],
                },
                component: () => import("../components/perfilUniversidad.vue"),
            },
            {
                path: "/universidad/vacantes",
                name: "Vacantes Universidad",
                meta: {
                    required: true,
                    roles: ["school"],
                },
                component: () => import("../components/vacantesUniversidad.vue"),
            },
        ],
    },
    {
        path: "/empresa",
        name: "Empresa",
        meta: {
            required: true,
            roles: ["company"],
        },
        component: () => import("../components/layouts/empresa.layouts"),
        children: [
            {
                path: "/empresa/perfil",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                name: "Perfil Empresa",
                component: () => import("../components/Perfilempresa.vue"),
            },
            {
                path: "/informationCompany",
                name: "Información de la Empresa",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/informationCompany.vue"),
            },
            {
                path: "/vacantes/empresa",
                name: "Vacantes Empresa",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/Vacantes.vue"),
            },
            {
                path: "/vacantes/empresa/editarvacante/:id",
                name: "Editar Vacante",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/UpdateVacante.vue"),
            },
            {
                path: "/talento",
                name: "Talento",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/Talento.vue"),
            },
            {
                path: "/becarioform",
                name: "Formulario Becario",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/BecarioForm.vue"),
            },
            {
                path: "/egresadosform",
                name: "Formulario Egresado",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/EgresadoForm.vue"),
            },
            {
                path: "/vacante/:id/postulantes",
                name: "Postulantes Vacante",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/InformacionPostulantes.vue"),
            },
            {
                path: "/client/vacante/:id/postulantes",
                name: "Postulantes Vacante Cliente",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/infoClientPostulante.vue"),
            },
            {
                path: "/dashboard",
                name: "dashboard-company",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/DasboardCompany.vue"),
            },
            {
                path: "/datatable",
                name: "dashboard-datatable",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/DashboardDatatable.vue"),
            },
            {
                path: "/formulario-vacante",
                name: "registrar-vacante",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/FormularioVacante.vue"),
            },
            {
                path: "/formulario-vacante/:id",
                name: "edit-vacante",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/FormularioVacante.vue"),
            },
            {
                path: "/formulario-vacante/:id/:details",
                name: "ver-vacante",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/FormularioVacante.vue"),
            },
            {
                path: "/estadisticos/apex-vacantes-totales",
                name: "apex-vacantes-totales",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/estadisticos/ApexChart.vue"),
            },
            {
                path: "/estadisticos/vacantes-totales",
                name: "vacantes-totales",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/estadisticos/VacantesTotales.vue"),
            },
            {
                path: "/estadisticos/vacantes-nivel",
                name: "vacantes-nivel",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/estadisticos/VacantesByNivel.vue"),
            },
            {
                path: "/estadisticos/vacantes-company",
                name: "vacantes-company",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/estadisticos/VacantesByCompany.vue"),
            },
            {
                path: "/estadisticos/vacantes-responsable",
                name: "vacantes-responsable",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/estadisticos/VacantesByResponsable.vue"),
            },
            {
                path: "/estadisticos/vacantes-area-pie",
                name: "vacantes-area-pie",
                meta: {
                    required: false,
                    roles: ["company", "admin"],
                },
                component: () => import("../components/estadisticos/VacantesByAreaPie.vue"),
            },
            {
                path: "/estadisticos/vacantes-status-pie",
                name: "vacantes-status-pie",
                meta: {
                    required: false,
                    roles: ["company", "admin"],
                },
                component: () => import("../components/estadisticos/VacantesByStatusPie.vue"),
            },
            {
                path: "/estadisticos/vacantes-promedio",
                name: "vacantes-promedio",
                meta: {
                    required: true,
                    roles: ["company", "admin"],
                },
                component: () => import("../components/estadisticos/VacantesByPromedioDiasCierre.vue"),
            },
            {
                path: "/estadisticos/vacantes-cierre",
                name: "vacantes-cierre",
                meta: {
                    required: true,
                    roles: ["company", "admin"],
                },
                component: () => import("../components/estadisticos/VacantesByCierre.vue"),
            },
            {
                path: "/estadisticos/vacantes-no-cierre",
                name: "vacantes-no-cierre",
                meta: {
                    required: true,
                    roles: ["company", "admin"],
                },
                component: () => import("../components/estadisticos/VacantesByNoCierre.vue"),
            },
            {
                path: "/estadisticos/vacantes-fin-terna",
                name: "vacantes-fin-terna",
                meta: {
                    required: true,
                    roles: ["company", "admin"],
                },
                component: () => import("../components/estadisticos/VacanteByFechaFinVsFechaTerna.vue"),
            },
            {
                path: "/estadisticos/vacantes-kpi",
                name: "vacantes-kpi",
                meta: {
                    required: true,
                    roles: ["company", "admin"],
                },
                component: () => import("../components/estadisticos/VacantesByKpi.vue"),
            },
            {
                path: "/administration/mycompany",
                name: "Administracion-Empresa",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/administration/AdminCompany.vue"),
            },
            {
                path: "/administration/cargarcfdis",
                name: "Administracion-Cfdis",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/administration/AdminCargarCFDIs.vue"),
            },
            {
                path: "/administration/clients",
                name: "Clientes",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/administration/RegisterClient.vue"),
            },
            {
                path: "/administration/clients/contributors",
                name: "Colaborador",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/administration/Contributors.vue"),
            },
            {
                path: "/administration/clients/list/candidates",
                name: "Candidatos",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/administration/ListCandidateClient.vue"),
            },
            {
                path: "/administration/clients/list/vacantes/:id",
                name: "Vacantes Cliente",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/administration/ListVacantesClient.vue"),
            },
            {
                path: "/administration/clients/vacante/:vacante/becario/:postulante",
                name: "Becario - Administración",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/administration/AdministrationBecario.vue"),
            },
            {
                path: "/administration/vacante/:vacante/egresado/:postulante",
                name: "Egresado - Administración",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/administration/AdministrationEgresado.vue"),
            },
            {
                path: "/administration/clients/vacante/:id/becario",
                name: "Becario - Cliente Administración",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/administration/AdminBecarioClient.vue"),
            },
            {
                path: "/administration/clients/vacante/:id/egresado",
                name: "Egresado - Cliente Administración",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/administration/AdminEgresadoClient.vue"),
            },
            {
                path: "/administration/clients/list/contratados",
                name: "Contratados",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/administration/ListContratadosClients.vue"),
            },
            {
                path: "/information/clients/vacante/:vacante/becario/:postulante",
                name: "BecarioCliente - Consulta",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/administration/costumerInfoBecario.vue"),
            },
            {
                path: "/information/clients/vacante/:vacante/egresado/:postulante",
                name: "EgresadoCliente - Consulta",
                meta: {
                    required: true,
                    roles: ["company"],
                },
                component: () => import("../components/administration/costumerInfoEgresado.vue"),
            },
        ],
    },

    {
        path: "/student",
        name: "Perfil",
        meta: {
            required: false,
            roles: ["student"],
        },

        component: () => import("../components/layouts/profile.layouts"),
        children: [
            {
                path: "/profile",
                name: "Estudiante",
                meta: {
                    required: true,
                    roles: ["student"],
                },
                component: () => import("../components/profile/profileStudent.vue"),
            },
            {
                path: "/editinformationstudent",
                name: "Editar Informacion",
                meta: {
                    required: true,
                    roles: ["student"],
                },
                roles: ["student"],
                component: () => import("../components/profile/informationStudent.vue"),
            },
            {
                path: "/informationStudent",
                name: "Informacion de Usuario",
                meta: {
                    required: true,
                    roles: ["student"],
                },
                roles: ["student"],
                component: () => import("../components/profile/viewDatosStudent.vue"),
            },
            {
                path: "/about",
                name: "About",
                meta: {
                    required: true,
                    roles: ["student"],
                },
                component: () => import("../components/profile/info"),
            },
            {
                path: "/uservacantes",
                name: "Vacantes",
                meta: {
                    required: true,
                    roles: ["student"],
                },
                roles: ["student"],
                component: () => import("../components/profile/vacantes"),
            },
            {
                path: "/postulaciones",
                name: "Postulaciones",
                meta: {
                    required: true,
                    roles: ["student"],
                },
                component: () => import("../components/profile/Postulaciones"),
            },
            {
                path: "/recibos",
                name: "Recibos",
                meta: {
                    required: true,
                    roles: ["student"],
                },
                component: () => import("../components/profile/Recibos"),
            },
            {
                path: "/postulaciones/documentos/:id",
                name: "Documentos",
                meta: {
                    required: true,
                    roles: ["student"],
                },
                component: () => import("../components/profile/Documentos"),
            },
            {
                path: "/verificate",
                name: "Verificacion de Usuario",
                meta: {
                    required: true,
                    roles: ["student"],
                },
                roles: ["student"],
                component: () => import("../components/profile/verificationStudent.vue"),
            },

        ],
    },
    {
        path: "/admin",
        name: "admin",
        meta: {
            required: true,
            roles: ["admin"],
        },
        component: () => import("../components/layouts/admin.layouts"),
        children: [
            {
                path: "/admin",
                name: "dashboard",
                component: () => import("../components/admin/Dashboard"),
            },
            {
                path: "/admin/historial-registro",
                name: "historial-registro",
                component: () => import("../components/admin/HistorialRegistros"),
            },
            {
                path: "/admin/historial-empresa",
                name: "historial-empresa",
                component: () => import("../components/admin/HistorialEmpresa"),
            },
            {
                path: "/admin/vacantes",
                name: "vacantes",
                component: () => import("../components/admin/adminVacantes.vue"),
            },
            {
                path: "/admin/registrosincompletos",
                name: "registros-incompletos",
                component: () => import("../components/admin/RegistrosIncompletos"),
            },
            {
                path: "/admin/universidades",
                name: "historial-universidades",
                component: () => import("../components/admin/HistorialUniversidades.vue"),
            },
            {
                path: "/admin/verificacion",
                name: "verificacion-estudiantes",
                component: () => import("../components/admin/HistorialVerificacion.vue"),
            },
            {
                path: "/admin/promotionalCode",
                name: "codigos-promocionales",
                component: () => import("../components/admin/codigosPromo.vue"),
            },
        ],
    },
];

const newRouter = createRouter({
    history: createWebHistory(),
    routes,
});

newRouter.beforeEach((to, from, next) => {
    const user = JSON.parse(localStorage.getItem("user")) || null;
    if (!to.meta || !to.meta.required) {
        document.title = `${process.env.VUE_APP_TITLE}`;
        next();
        return;
    }

    if (!user || !user.token) {
        next("/");
        return;
    }

    if (to.meta.roles) {
        const hasAccess = to.meta.roles.some(role => user.roles.includes(role));
        console.log(hasAccess);
        if (hasAccess) {
            document.title = `${process.env.VUE_APP_TITLE} | ${to.name || ''}`;
            next();
        } else {
            next("/");
        }
    } else {
        document.title = `${process.env.VUE_APP_TITLE} | ${to.name || ''}`;
        next();
    }
});

export default newRouter;
