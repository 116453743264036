import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueApexCharts from "vue3-apexcharts";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'boxicons'


//Fonts
import '@fontsource-variable/jost';
import '@fontsource/poppins';
import '@fontsource/roboto';
// Supports weights 100-900
import '@fontsource-variable/inter';


const app = createApp(App).use(router);
app.component('QuillEditor', QuillEditor);
app.directive("sensibilidad", {
  mounted(el) {
    el.addEventListener("input", () => {
      const palabrasProhibidas = ["sexo", "pornografía", "violencia", "palabra1", "palabra2"];
      const preguntaUsuario = el.value.toLowerCase();
      const regex = new RegExp(palabrasProhibidas.join("|"), "gi");
      const contienePalabraProhibida = regex.test(preguntaUsuario);
      if (contienePalabraProhibida) {
        alert("La pregunta contiene palabras prohibidas. Por favor, reformula tu pregunta.");
        el.value = "";
      }
    });
  },
});


app.use(axios);
app.use(VueSweetalert2);
app.use(VueApexCharts);
app.use(router);
app.mount("#app");
